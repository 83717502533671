section.contact-page {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;

  header.contact-header {
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 700px;

    .inner {
      text-align: center;

      h2 {
        font-weight: 300;
        color: #808080;
        margin-bottom: 15px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 50px;

    li {
      padding-bottom: 30px;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }
    }
  }
}

.having-trouble {
  padding: 50px;
  text-align: center;

  a {
    display: block;
    padding-top: 10px;
    color: var(--orange);
    text-decoration: none;

    &:hover {
      color: var(--text);
    }
  }
}
