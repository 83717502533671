.navbar {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100px;
  background-color: var(--background);
  box-shadow: 0px 2px 6px rgba(80, 80, 80, 0.15);
  padding: 0 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 300;

  .logo {
    margin-right: auto;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding: 30px 0;
    text-decoration: none;

    .image {
      height: 100%;
      width: auto;
      display: inline-block;
      margin-right: auto;

      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }

      img[mobile] {
        display: none;
      }

      @media screen and (max-width: 530px) {
        img[desktop] {
          display: none;
        }

        img[mobile] {
          display: block;
        }
      }
    }

    @media screen and (max-width: 530px) {
      padding: 20px 0;
    }
  }

  .content {
    ul.nav-list {
      height: 100%;
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;

      li.nav-list-item {
        padding: 0 10px;
        position: relative;

        span,
        a {
          display: block;
          line-height: 34px;
          font-weight: 400;
          padding: 0 20px;
          font-size: 16px;
          text-transform: uppercase;
          display: block;
          color: var(--color);
          text-decoration: none;
          background-color: #ffffff;
          position: relative;
          cursor: pointer;

          &.active {
            color: var(--orange);

            &::after {
              opacity: 1;
            }
          }
        }

        span {
          i {
            all: unset;
            font-style: normal;
          }

          ul.hover-nav {
            position: absolute;
            right: 0;
            top: calc(100% + 10px);
            list-style: none;
            display: block;
            padding: 0;
            margin: 0;
            background-color: var(--text);
            min-width: 250px;
            padding: 15px 0;
            transform: translateY(10px);
            opacity: 0;
            pointer-events: none;
            will-change: transform, opacity;
            transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 250ms;

            li {
              width: 100%;
            }

            a {
              background-color: transparent;
              color: #ffffff;
              line-height: 50px;
              text-align: center;

              &.active::after,
              &.active::before {
                display: none;
              }

              &.active,
              &:hover {
                color: var(--orange);
              }
            }

            &::after,
            &::before {
              content: '';
              display: block;
              position: absolute;
            }

            &::after {
              top: -10px;
              width: 100%;
              height: 10px;
              left: 0;
            }

            &::before {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent var(--text) transparent;
              top: -10px;
              right: 25%;
            }
          }

          &:hover ul.hover-nav {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
          }
        }

        a {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
          will-change: color, box-shadow, background-color;
          transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background-color: var(--orange);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
            opacity: 0;
          }
        }
      }
    }

    .menu-button {
      height: 100%;
      width: 80px;
      padding: 0;
      margin: 0;
      appearance: none;
      border: none;
      position: relative;
      outline: none;
      background-color: #ffffff;

      span {
        background-color: #3b3b3b;
        width: 50px;
        height: 4px;
        border-radius: 2px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -2px;
        transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms 200ms,
          margin cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms 200ms,
          transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;

        &:nth-child(1) {
          margin-top: -14px;
        }

        &:nth-child(3) {
          margin-top: 10px;
        }
      }

      &.open {
        span {
          margin-top: -2px !important;
          transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms,
            margin cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms,
            transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms 200ms;

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.cdk-menu-backdrop {
  position: fixed;
  top: 100px;
  left: 0;
  height: calc(100vh - 100px);
  width: 100%;
  background-color: hsla(0, 0%, 23%, 0.5);
  opacity: 1;
  backdrop-filter: blur(5px);
  z-index: 200;
  display: none;

  &.open {
    display: block;
  }
}

menu.navbar-option-menu {
  display: block;
  appearance: none;
  padding: 0;
  margin: 0;
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 90%;
  max-width: 400px;
  max-height: calc(100vh - 120px);
  background-color: #ffffff;
  opacity: 0;
  z-index: 201;
  transform: translate3d(-50%, 100%, 0);
  transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms,
    transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;

  .content {
    padding: 20px;

    .nav-list {
      list-style: none;
      margin: 0;
      padding: 0;

      .nav-list-item {
        width: 100%;
        height: auto;

        a,
        span {
          display: block;
          line-height: 45px;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          color: #3b3b3b;

          &.active {
            color: #f59327;
            font-weight: bold;
            letter-spacing: 2px;
          }

          i {
            font-style: normal;
          }
        }

        span {
          i {
            display: none;
          }

          ul.hover-nav {
            list-style: none;
            display: block;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  &.open {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
}
