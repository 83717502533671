section.schedule-page {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;

  header.schedule-header {
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      text-align: center;

      h2 {
        font-weight: 300;
        color: #808080;
        margin-bottom: 15px;
      }
    }
  }

  article.times-list {
    .times-table {
      width: 100%;
      height: auto;
      margin-bottom: 30px;

      .table-header {
        background-color: #ffffff;
        position: sticky;
        top: 100px;
        left: 0;
        width: 100%;
        min-height: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f59327;
        padding: 10px 20px;
        color: #ffffff;

        a {
          color: var(--background);

          &:hover {
            color: var(--text);
          }
        }
      }

      .info {
        width: 100%;
        height: auto;
        padding: 20px;

        a {
          color: var(--orange);
          text-decoration: none;
          font-weight: 400;

          &:hover {
            color: var(--text);
          }
        }

        .row {
          display: flex;
          align-items: center;
          height: 45px;
          border-bottom: solid 1px #cfcfcf;

          .col {
            flex: 1;
            max-width: 50%;
            font-size: 20px;
            font-weight: 300;
            color: var(--color);

            &:nth-child(2) {
              text-align: right;
              font-weight: 400;
              color: #808080;
            }
          }

          &:nth-last-child(1) {
            border-bottom: none;
          }

          @media screen and (max-width: 500px) {
            display: block;
            padding: 15px 0;
            height: auto;

            .col {
              max-width: unset;
              width: 100%;
              padding: 0;
              text-align: left !important;
            }
          }
        }
      }

      &.noop {
        opacity: 0.7;

        .table-header {
          background-color: #808080;
        }

        .info {
          text-decoration: line-through;
        }
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}
