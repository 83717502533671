article.about {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;

  header.about-header {
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    a {
      color: var(--orange);
      text-decoration: none;
      font-weight: 400;

      &:hover {
        opacity: 0.7;
      }
    }

    .inner {
      text-align: center;

      h2 {
        font-weight: 300;
        color: #808080;
        margin-bottom: 15px;
      }
    }
  }

  section.content-section {
    padding: 10px 0;

    h5 {
      padding-bottom: 15px;
      font-weight: bold;
      color: var(--orange);
    }

    p {
      line-height: 2;
    }
  }
}

a {
  color: var(--orange);
  text-decoration: none;
  display: inline;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
}
