.loader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    width: 120px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      height: 20px;
      width: 20px;
      background-color: var(--orange);
      animation: Load 400ms ease 0ms infinite alternate;

      &:nth-child(1) {
        animation-delay: 0ms;
      }

      &:nth-child(2) {
        animation-delay: 50ms;
      }

      &:nth-child(3) {
        animation-delay: 100ms;
      }

      &:nth-child(4) {
        animation-delay: 150ms;
      }

      &:nth-child(5) {
        animation-delay: 200ms;
      }
    }
  }
}

@keyframes Load {
  from {
    height: 20px;
  }

  to {
    height: 60px;
  }
}
