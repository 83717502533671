section.giving-page {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;

  header.giving-header {
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      text-align: center;

      h2 {
        font-weight: 300;
        color: #808080;
        margin-bottom: 15px;
      }
    }
  }

  section.content-section {
    padding: 10px 0;

    h5 {
      padding-bottom: 15px;
      font-weight: bold;
      color: var(--orange);
    }

    p {
      line-height: 2;
    }

    .bank-details {
      display: block;
      list-style: none;
      padding: 15px 0;
      margin: 0;

      li {
        padding: 5px 15px;
        border: solid 1px #808080;
        border-bottom: none;

        p {
          margin: 0;
          padding: 0;
        }

        &:first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-bottom: solid 1px #808080;
        }
      }
    }
  }
}

