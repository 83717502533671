section.subscribe-page {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;

  header.subscribe-header {
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 700px;

    .inner {
      text-align: center;

      h2 {
        font-weight: 300;
        color: #808080;
        margin-bottom: 15px;
      }
    }
  }

  .subscribe-form {
    form {
      display: flex;
      width: 100%;
      gap: 20px;

      .form-control {
        flex: 1;

        input {
          display: block;
          width: 100%;
          height: 50px;
          border: solid 1px #808080;
          font-size: 20px;
          font-weight: 300;
          text-indent: 20px;
        }
      }

      .form-control.button {
        flex: 0;

        button {
          width: 180px;
          display: block;
          background-color: var(--orange);
          height: 50px;
          margin: 0;
          border: none;
          padding: 0;
          color: var(--background);
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 300;
          cursor: pointer;
        }
      }

      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      text-align: center;
      max-width: 500px;
      margin: auto;
      background-color: var(--background);
      padding: 15px;
      border: solid 2px var(--orange);
      font-weight: 300;
    }
  }
}