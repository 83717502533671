section.home {
  display: block;
  width: 100%;

  header {
    width: 100%;
    height: calc(100vh - 100px);
    background: #ffffff;
    overflow: hidden;
    position: relative;

    img[alt='logo'] {
      width: 50%;
      max-width: 190px;
      display: block;
      margin: 0 auto;
    }

    .content {
      padding: 40px 50px;
      max-width: 850px;
      width: 90%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, 0.92);
      z-index: 100;
      text-align: center;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      backdrop-filter: blur(3px);

      h1 {
        font-weight: 300;
        color: var(--color);

        @media screen and (max-width: 600px) {
          font-size: 2.8em;
        }
      }

      hr {
        border: none;
        width: 100%;
        max-width: 300px;
        height: 6px;
        background: #d0d0d0;
        border-radius: 5px;
        margin: 40px auto;
      }

      a {
        display: inline-block;
        line-height: 40px;
        padding: 0 25px;
        background-color: #ffffff;
        background: #ffffff;
        color: #f59327;
        border: solid 2px #f59327;
        text-decoration: none;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin: 0 10px;

        &:hover {
          background-color: #f59327;
          color: #ffffff;
        }

        &.filled {
          background-color: #f59327;
          color: #ffffff;

          &:hover {
            background-color: #ffffff;
            color: #f59327;
          }
        }

        @media screen and (max-width: 500px) {
          margin-bottom: 15px;
        }
      }
    }

    .image {
      flex: 1;
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        transform-origin: right center;
        object-fit: cover;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.25);
      }
    }

    @media screen and (max-width: 1100px) {
      display: block;
      padding: 0;
      height: 70vh;

      .content {
        width: 100%;
        height: auto;
        padding-bottom: 40px;
        max-width: unset;
        border-radius: 0;
      }
    }

    @media screen and (max-width: 500px) {
      height: auto;

      .content {
        position: static;
        transform: none;
      }

      .image {
        display: none;
      }
    }
  }
}

.youtube-video {
  iframe {
    display: block;
    width: 100%;
    height: 400px;

    @media screen and (max-width: 530px) {
      height: 200px;
    }
  }
}

.newsroom {
  background-color: var(--color);
  padding: 50px;

  .inner-wrapper {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    text-align: center;

    p {
      color: var(--background);
      padding-bottom: 15px;
    }

    .action a {
      display: inline-block;
      line-height: 40px;
      border: solid 2px var(--background);
      padding: 0 30px;
      color: var(--background);
      text-decoration: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        color: var(--orange);
        border-color: var(--orange);
      }
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}

.actions {
  background-color: var(--color);

  .actions-wrapper {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    padding: 0;
    list-style: none;
    display: flex;

    .action {
      flex: 1;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      .inner {
        width: 90%;
        height: auto;
        padding: 25px 0;
        cursor: pointer;
        border-radius: 15px;
        background-color: var(--color);
        will-change: background-color;
        transition: background-color cubic-bezier(0.455, 0.03, 0.515, 0.955)
          300ms;

        .icon {
          width: 90px;
          margin: auto auto 15px auto;

          svg path {
            will-change: fill;
            transition: fill cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
          }
        }

        .label {
          color: var(--background);
          text-align: center;
          font-size: 20px;
          font-weight: 300;
        }

        &:hover {
          background-color: #f59327;

          .icon svg path {
            fill: #ffffff;
          }
        }
      }
    }

    @media screen and (max-width: 500px) {
      display: block;
      padding: 30px 0;
      width: 80%;
      max-width: 280px;

      .action {
        height: auto;
        display: block;
        margin: 0;
        transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

        .inner {
          display: flex;
          align-items: center;
          margin: 0;

          .icon {
            margin: 0;

            svg {
              width: 80%;
              display: block;
            }
          }

          .label {
            flex: 1;
            padding-left: 15px;
            text-align: left;
            margin: 0;
          }

          &:hover {
            background-color: var(--color) !important;

            .icon svg path {
              fill: var(--orange) !important;
            }
          }
        }

        &:active {
          transform: scale(1.08);
        }
      }
    }
  }
}

.sermon-lists {
  padding: 50px;
  width: 100%;
  height: auto;
  border-top: solid 2px #d0d0d0;
  border-bottom: solid 2px #d0d0d0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: block;
    width: 90%;
    max-width: 1000px;

    li {
      display: block;
      padding-bottom: 40px;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }
    }
  }
}

.about-section {
  padding: 50px;
  background-color: var(--background);

  .inner-wrapper {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    text-align: left;

    a {
      color: var(--orange);
      font-weight: 400;
      text-decoration: none;

      &:hover {
        opacity: 0.7;
      }
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}
