.verse-wrapper {
  width: 100%;
  height: auto;
  padding: 90px 20px;

  .inner {
    width: 90%;
    max-width: 1000px;
    margin: auto;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon {
        width: 60px;

        svg {
          display: block;
          width: 100%;
        }
      }

      .info {
        padding-left: 20px;

        h5,
        h6 {
          margin: 0;
        }

        h5 {
          font-size: 22px;
          color: var(--color);

          a {
            color: var(--color);
            text-decoration: none;
          }
        }

        h6 {
          font-size: 14px;
          font-weight: 400;
          color: #f59327;
        }
      }
    }

    .verse-html {
      p {
        font-weight: 300;
        font-size: 20px;
      }
    }

    .verse-copy {
      small {
        font-size: 50%;

        a {
          color: #808080;
          text-decoration: none;

          &:hover {
            color: #f59327;
          }

          &.copy {
            display: block;
            line-height: normal;
          }
        }

        strong {
          display: block;
          text-align: right;
          padding-top: 7px;
        }
      }
    }
  }
}
