.sermons-page {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;

  nav.search {
    display: block;
    width: 100%;
    padding: 30px 0;

    .search-back {
      padding-right: 25px;

      a {
        display: block;
        height: 55px;
        line-height: 55px;
        padding-left: 15px;
        text-decoration: none;
        color: var(--orange);
        position: relative;

        span {
          position: absolute;
          width: 10px;
          height: 10px;
          border: solid 2px var(--orange);
          left: 1px;
          top: 50%;
          margin-top: -5px;
          transform: rotate(45deg);
          border-right: none;
          border-top: none;
        }
      }
    }

    .search-inner {
      display: flex;
      align-items: center;

      .search-input {
        flex: 1;
        height: 55px;

        input {
          display: block;
          width: 100%;
          height: 100%;
          height: 55px;
          font-size: 20px;
          text-indent: 15px;
          font-weight: lighter;
          border: solid 2px var(--text);
          border-radius: 40px 0 0 40px;
          border-right: 0;
          margin: 0;
        }
      }

      .search-button {
        height: 55px;
        display: block;

        button {
          height: 100%;
          display: block;
          border-radius: 0 40px 40px 0;
          padding: 0 20px;
          font-size: 16px;
          letter-spacing: 1px;
          font-weight: lighter;
          text-transform: uppercase;
          border: none;
          background-color: var(--text);
          color: #ffffff;
          cursor: pointer;

          &:hover {
            background-color: var(--orange);
          }
        }
      }
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  header.sermons-header {
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      text-align: center;

      h2 {
        font-weight: 300;
        color: #808080;
        margin-bottom: 15px;
        text-align: center;
      }

      p {
        max-width: 650px;
        margin: auto;
      }
    }
  }

  .sermons {
    padding: 30px 0;

    .sermon-options {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;

      .option {
        display: block;
        cursor: pointer;
        text-decoration: none;

        .icon {
          width: 90%;
          max-width: 100px;
          margin: auto;

          svg {
            display: block;
            width: 100%;
            fill: var(--text);
          }
        }

        .title {
          text-align: center;
          font-size: 22px;
          font-weight: 300;
          color: var(--text);
        }

        &:hover {
          .icon svg {
            fill: var(--orange);
          }
        }
      }

      @media screen and (max-width: 500px) {
        display: block;

        .option {
          padding-bottom: 30px;
        }

        .option:last-child {
          padding-bottom: 0;
        }
      }

      &.small {
        display: flex !important;

        .option {
          padding-bottom: 0px;

          .icon {
            max-width: 50px;
          }

          .title {
            display: none;
          }
        }
      }
    }
  }

  .latest-sermons {
    padding: 50px 0;

    ul {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 50px;

        &:nth-last-child(1) {
          padding-bottom: 0;
        }
      }
    }

    @media screen and (max-width: 500px) {
      padding: 20px 0;
    }
  }

  .sermons ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-bottom: 50px;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }
    }
  }
}
