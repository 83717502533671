.podcast-card {
  display: block;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  width: 100%;
  text-decoration: none;
  text-align: center;

  .inner {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    figure {
      padding: 0;
      display: block;
      margin: 0;
      width: 300px;
      height: 300px;
      position: relative;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .play,
      .pause {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        width: 55px;
        height: auto;
        transform: translate(-50%, -50%);

        svg {
          display: block;
          width: 100%;
        }
      }

      .pause {
        visibility: hidden;
      }

      &.playing {
        .play {
          visibility: hidden;
        }

        .pause {
          visibility: visible;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .content {
      padding: 20px 30px;
      padding-bottom: 40px;
      text-align: left;
      flex: 1;
      max-width: calc(100% - 300px);
      position: relative;

      .episode-audio {
        width: 100%;
        height: auto;
        padding: 15px 0;
        display: none;

        audio {
          display: block;
          width: 100%;
        }
      }

      .episode-content {
        padding-top: 15px;

        p {
          margin: 0;
          padding: 0;
          line-height: 1.5;
          font-size: 16px;

          a {
            pointer-events: none;
            color: inherit;
          }
        }
      }

      .episode-action {
        padding-top: 10px;
      }

      .episode-progress {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        progress {
          appearance: none;
          display: block;
          width: 100%;
          height: 10px;
          margin: 0;
          padding: 0;
          visibility: hidden;

          &::-webkit-progress-bar {
            background-color: var(--light-background);
          }

          &::-webkit-progress-value {
            background: var(--orange);
          }
        }
      }
    }

    @media screen and (max-width: 795px) {
      display: block;

      figure {
        width: 100%;
        height: auto;
      }

      .content {
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 795px) {
    max-width: 450px;
    margin: auto;
  }
}
