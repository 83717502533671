.contact-card {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0 rgba(77, 77, 77, 0.15);
  padding: 10px 20px;

  .avatar {
    width: 80px;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      border: solid 1px #dddddd;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .information {
    padding-left: 20px;

    h5 {
      font-size: 16px;
      margin-bottom: 5px;
    }

    h6 {
      font-size: 20px;
      color: #808080;
      font-weight: 400;

      em {
        color: var(--color);
      }
    }
  }

  .details {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    a {
      display: block;
      color: #f59327;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding: 20px;

    .avatar {
      margin: auto;
      padding-bottom: 15px;
    }

    .information,
    .details {
      display: block;
      padding-left: 0;
      text-align: center;
    }

    .information {
      padding-bottom: 5px;
    }
  }
}
